<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Critical Permissions</h5>
                <div>
                    <Button label="Dashboard" class="p-button-sm p-mr-2" @click="goToDashboard" icon="pi pi-th-large" />
                    <Button label="Previous" class="p-button-sm p-mr-1" @click="goToPrevious" icon="pi pi-chevron-left" />
                </div>
            </div>
            <div class="card">
                <div>
                    <span class="subtext p-mt-2 p-text-capitalize">
                        <h6>Admin Name : {{ this.localAdminName ? this.localAdminName : '-' }} </h6>
                    </span>
                </div>
                <div style="position: relative" v-if="!showLoader">
                    <div class="p-py-4 p-px-3">
                        <div class="p-fluid p-formgrid p-grid">
                            <DataTable :value="criticalPermissionsList" :lazy="true" :paginator="false" :rows="30"
                                :totalRecords="totalRecords" :loading="loading" @page="onPage" class="p-datatable-users"
                                data-key="value" :rowHover="true"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                                <template #empty> No data found. </template>
                                <template #loading> Loading data. Please wait... </template>
                                <Column field="value" header="Sr." headerStyle="width: 5%">
                                    <template #body="{ index }">
                                        {{ ++index }}
                                    </template>
                                </Column>
                                <Column header="Permissions" headerStyle="width: 50%">
                                    <template #body="{ data }">
                                        <div>{{ data.label }}</div>
                                    </template>
                                </Column>
                                <Column header="Admin Users" headerStyle="width: 25%">
                                    <template #body="{ index }">
                                        <MultiSelect v-model="admin_user_fks[index]" :options="adminUsersList"
                                            optionValue="value" optionLabel="label" placeholder="Select Users"
                                            :filter="false" class="multiselect-custom" appendTo="body"
                                            @change="updateModel(admin_user_fks[index], index)"></MultiSelect>
                                    </template>
                                </Column>
                                <Column header="Action" headerStyle="width: 20%">
                                    <template #body="{ data, index }">
                                        <span v-if="admin_user_fks[index] == null"><Button label="Allow"
                                                class="p-button-text"
                                                @click="updateAdminUserCriticalPermissions(data, admin_user_fks[index])"
                                                style="width: 50px" disabled /></span>
                                        <span v-if="admin_user_fks[index] != null"><Button label="Allow"
                                                class="p-button-text"
                                                @click="updateAdminUserCriticalPermissions(data, admin_user_fks[index])"
                                                style="width: 50px" /></span>

                                        <span v-if="admin_user_fks[index] != null && serverStatus[index] == null"><Button
                                                label="Remove" class="p-button-text"
                                                @click="removeAdminUserCriticalPermissions(data, admin_user_fks[index])"
                                                style="width: 65px" disabled /></span>
                                        <span v-if="admin_user_fks[index] != null && serverStatus[index] != null"><Button
                                                label="Remove" class="p-button-text"
                                                @click="removeAdminUserCriticalPermissions(data, admin_user_fks[index])"
                                                style="width: 65px" /></span>
                                    </template>
                                </Column>
                            </DataTable>
                            <Toast />
                        </div>
                    </div>
                </div>
                <div v-if="showLoader" style="height: 390px; position: relative">
                    <div class="custom-modal-spinner-loader">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from '../../service/ApiService';
import { useRoute } from 'vue-router';
import router from '@/router';

export default {
    data() {
        return {
            criticalPermissionsList: [{ value: '1', label: 'Allow to display mobile number', key: 'ad39' },
            { value: '2', label: 'Allow to display email id', key: 'ad40' },
            { value: '3', label: 'Allow to use mobile application', key: 'ad41' },
            { value: '4', label: 'Allow to edit user', key: 'ad42' },
            { value: '5', label: 'Allow to display adhar number', key: 'ad47' }
            ],
            showLoader: false,
            loading: false,
            page_no: 0,
            totalRecords: 0,
            localAdminName: '',
            adminUsersList: [],
            admin_user_fks: [],
            routeParam: 0,
            disabledFlag: false,
            serverStatus: [],
        };
    },

    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.loading = true;
        const route = useRoute();
        this.routeParam = route.params.adminSubId;
        this.localAdminName = localStorage.localAdminName;
        this.getAdminUsersOnly();
        this.getAdminUserCriticalPermissions();
    },
    methods: {
        async getAdminUsersOnly() {
            try {
                const params = { clientId: this.routeParam };
                ({ data: this.adminUsersList } = await this.ApiService.getAdminUsersOnly(params));
            } catch ({ error }) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
            }
        },

        updateModel(e, i) {
            if (e.length == 0) {
                this.admin_user_fks[i] = null;
                this.disabledFlag = false;
            } else {
                this.disabledFlag = true;
            }
        },

        async getAdminUserCriticalPermissions() {
            const params = { clientId: this.routeParam };
            var res = await this.ApiService.getAdminUserCriticalPermissions(params);
            if (res) {
                for (let i = 0; i <= 5; i++) {
                    if (i == 0) {
                        let user = res.ad39;
                        let arr = user;
                        if (arr) {
                            let usr_fks = arr.toString();
                            if (usr_fks != '') {
                                let fk = usr_fks.split(',').map(Number);
                                this.admin_user_fks[i] = fk;
                                this.serverStatus[i] = fk
                            } else {
                                this.admin_user_fks[i] = null;
                                this.serverStatus[i] = null
                            }
                        }
                    }
                    if (i == 1) {
                        let user = res;
                        let arr = user.ad40;
                        if (arr) {
                            let usr_fks = arr.toString();
                            if (usr_fks != '') {
                                let fk = usr_fks.split(',').map(Number);
                                this.admin_user_fks[i] = fk;
                                this.serverStatus[i] = fk
                            } else {
                                this.admin_user_fks[i] = null;
                                this.serverStatus[i] = null
                            }
                        }
                    }
                    if (i == 2) {
                        let user = res.ad41;
                        let arr = user;
                        if (arr) {
                            let usr_fks = arr.toString();
                            if (usr_fks != '') {
                                let fk = usr_fks.split(',').map(Number);
                                this.admin_user_fks[i] = fk;
                                this.serverStatus[i] = fk
                            } else {
                                this.admin_user_fks[i] = null;
                                this.serverStatus[i] = null
                            }
                        }
                    }
                    if (i == 3) {
                        let user = res.ad42;
                        let arr = user;
                        if (arr) {
                            let usr_fks = arr.toString();
                            if (usr_fks != '') {
                                let fk = usr_fks.split(',').map(Number);
                                this.admin_user_fks[i] = fk;
                                this.serverStatus[i] = fk
                            } else {
                                this.admin_user_fks[i] = null;
                                this.serverStatus[i] = null
                            }
                        }
                    }
                    if (i == 4) {
                        let user = res.ad47;
                        let arr = user;
                        if (arr) {
                            let usr_fks = arr.toString();
                            if (usr_fks != '') {
                                let fk = usr_fks.split(',').map(Number);
                                this.admin_user_fks[i] = fk;
                                this.serverStatus[i] = fk
                            } else {
                                this.admin_user_fks[i] = null;
                                this.serverStatus[i] = null
                            }
                        }
                    }
                }
                this.loading = false;
            }
        },

        updateAdminUserCriticalPermissions(payload1, payload2) {
            if (payload2 && this.admin_user_fks.length != 0) {
                let fields = {};
                fields['clientId'] = this.routeParam;
                fields['user_fks'] = payload2.toString();
                fields['key'] = payload1.key;
                this.loading = true;
                //this.showLoader = true;
                this.ApiService.updateAdminUserCriticalPermissions(fields).then((items) => {
                    if (items.success == true) {
                        //this.loading = false;
                        //this.showLoader = false;
                        var successMsg = 'Permission Applied Successfully.';
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.getAdminUserCriticalPermissions();
                    } else {
                        this.loading = false;
                        var errorMsg = 'Something went wrong, Please try after some time.';
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            } else {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Please Select Admin User', life: 3000 });
            }
        },

        removeAdminUserCriticalPermissions(payload1, payload2) {
            if (payload2 && this.admin_user_fks.length != 0) {
                let fields = {};
                fields['clientId'] = this.routeParam;
                fields['key'] = payload1.key;
                fields['remove_flag'] = 1;
                this.loading = true;
                //this.showLoader = true;
                this.ApiService.updateAdminUserCriticalPermissions(fields).then((items) => {
                    if (items.success == true) {
                        //this.loading = false;
                        //this.showLoader = false;
                        var successMsg = 'All Permission Removed Successfully.';
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.getAdminUserCriticalPermissions();
                        //router.go()
                    } else {
                        this.loading = false;
                        var errorMsg = 'Something went wrong, Please try after some time.';
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            } else {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Please Select Admin User', life: 3000 });
            }
        },

        goToDashboard() {
            router.push({
                name: "admindashboard",
                params: { adminId: this.routeParam },

            });
        },

        goToPrevious() {
            router.push({
                name: "web-bot-template-settings",
                params: { adminSubId: this.routeParam },

            });
        },
    }

};
</script>

<style scoped></style>
